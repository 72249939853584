<template>
  <div>
    <div class="row justify-content-start align-items-center mt-5 ml-2">
      <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      <!-- <button
        class="btn btn-info mr-1"
        v-b-tooltip.hover
        title="Download Pdf"
        @click="btnDownloadOnClick"
      >
        <i class="fas fa-file-pdf px-0"></i>
      </button> -->
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      >
        <i class="fas fa-file-excel px-0"></i>
      </button>
    </div>

    <!-- table -->
    <div class="d-flex justify-content-end align-items-center mt-6">
      <span
        style="width: 150px; color: #245590 !important"
        class="font-weight-bold"
        >Total :</span
      >
      <span class="font-weight-bold" style="color: #245590 !important"
        >Rp {{ summary.toLocaleString("id-ID") }}</span
      >
    </div>
    <b-table
      striped
      hover
      responsive
      class="mt-3"
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
    >
      <template #cell(created_at)="data">
        {{
          new Date(Date.parse(data.item.usage_date)).toLocaleString("id-ID", {
            weekday: "long",
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })
        }}
      </template>
      <template #cell(doctor_name)="data">
        {{ data.item.doctor == null ? "-" : data.item.doctor.name }}
      </template>
      <template #cell(patient_name)="data">
        {{ data.item.payment == null ? "-" : data.item.payment.patient_name }}
      </template>
      <template #cell(doctor_commission)="data">
        {{ parseInt(data.item.doctor_commission).toLocaleString("id-ID") }}
      </template>
    </b-table>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Komisi Dokter'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="780px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <template>
          <div id="print">
            <div class="d-flex justify-content-center mt-3">
              <h5>Laporan Komisi Dokter</h5>
            </div>
            <div class="d-flex justify-content-end align-items-center mt-6">
              <span
                style="width: 150px; color: #245590 !important"
                class="font-weight-bold"
                >Total :</span
              >
              <span class="font-weight-bold" style="color: #245590 !important"
                >Rp {{ summary.toLocaleString("id-ID") }}</span
              >
            </div>
            <b-table
              striped
              hover
              responsive
              class="mt-3"
              :items="items"
              :fields="fieldPrint"
              :style="'white-space: nowrap'"
            >
              <template #cell(created_at)="data">
                {{
                  new Date(Date.parse(data.item.usage_date)).toLocaleString(
                    "id-ID",
                    {
                      weekday: "long",
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    }
                  )
                }}
              </template>
              <template #cell(doctor_name)="data">
                {{ data.item.doctor == null ? "-" : data.item.doctor.name }}
              </template>
              <template #cell(patient_name)="data">
                {{
                  data.item.payment == null
                    ? "-"
                    : data.item.payment.patient_name
                }}
              </template>
              <template #cell(doctor_commission)="data">
                {{
                  parseInt(data.item.doctor_commission).toLocaleString("id-ID")
                }}
              </template>
            </b-table>
          </div>
        </template>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import XLSX from "xlsx";
import VueHtml2pdf from "vue-html2pdf";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    // hasPatient: Boolean,
    // items: Array,
    // summary: Array,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number,
    dataModal: Array,
    excel: Array,
  },

  components: {
    Treeselect,
    VueHtml2pdf,
    XLSX,
  },

  data() {
    return {
      // filter
      filter: {
        doctor_name: "",
        start_date: "",
        end_date: "",
        ref_id: "",
        ref_table: "",
      },
      // per Page
      number: 20,
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // table fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "payment_category_name",
          label: "Layanan",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_commission",
          label: "Komisi",
          sortable: true,
        },
      ],
      fieldPrint: [
        {
          key: "number",
          label: "#",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing",
        },
        {
          key: "created_at",
          label: "Tanggal",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing",
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing",
        },
        {
          key: "payment_category_name",
          label: "Layanan / Lab",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing",
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing",
        },
        {
          key: "doctor_commission",
          label: "Komisi",
          sortable: false,
          thClass: "text-sizing",
          tdClass: "text-sizing",
        },
      ],
      // table items
      items: [],
      excelData: [],
      // Select Options
      services: [],
      // Other
      summary: 0,
      dummy: "",
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {
    async getServiceOptions() {
      let response = await module.setPaymentServiceTreeSelect(
        "payment-services"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.services = response.data;
        this.services.unshift({
          label: "Filter Berdasar Layanan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async pagination() {
      this.excelData = this.excel;

      let displayExcel = [];
      this.excelData.forEach((el) => {
        let d = {
          "Tanggal Penggunaan": new Date(
            Date.parse(el.usage_date)
          ).toLocaleString("id-ID", {
            weekday: "long",
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }),
          "Nama Dokter": el.doctor == null ? "-" : el.doctor.name,
          "Nama Pasien": el.payment == null ? "-" : el.payment.patient_name,
          Layanan:
            el.payment_category_name == null ? "-" : el.payment_category_name,
          "Komisi Dokter": parseInt(el.doctor_commission),
          // 'Pemasukan Klinik' : parseInt(el.hospital_income)
        };

        displayExcel.push(d);
      });

      this.excelData = displayExcel;

      this.items = this.dataModal;

      let a = 0;
      this.summary = 0;
      this.items.forEach((value) => {
        this.summary += parseInt(value.doctor_commission);
        value.number = ++a;
      });
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print");
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress($event) {},

    hasStartedGeneration() {},

    hasGenerated($event) {},

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Komisi Dokter.xlsx");
    },
  },

  watch: {
    dummy: function (newVal) {
      if (typeof newVal === "undefined") {
        this.filter.ref_id = "";
        this.filter.ref_table = "";
        this.dummy = "";
        this.pagination();
      }
    },
  },

  mounted() {
    this.pagination();
    this.getServiceOptions();
  },
};
</script>

<style>
.text-sizing {
  font-size: 10px;
}
</style>