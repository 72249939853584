<template>
  <div>

    <b-modal
      id="modal-report"
      size="xl"
      :title="'Expor Laporan '+reportName"
      style="max-width: 30px; overflow: scroll;"
      hide-footer
    >
      <div v-if="reportType == 'payment-report'">
        <PaymentReport 
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>
      <div v-if="reportType == 'expense-report'">
        <ExpenseReport 
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>
      <div v-if="reportType == 'payment-pharmacy-report'">
        <!-- {{dataModal}}
        {{excel}} -->
        <PaymentPharmacyReport 
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>
      <div v-if="reportType == 'pharmacy-expense-report'">
        
        <PharmacyExpenseReport 
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>
      <div v-if="reportType == 'ecommerce-report'">
        <EcommerceRecordReport 
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>
      <div v-if="reportType == 'doctor-commision-report'">
        <DoctorCommisionReportTable 
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>
      <div v-if="reportType == 'employee-commision-report'">
        <EmployeeCommisionReportTable 
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>
      <div v-if="reportType == 'payroll-report'">
        <PayrollReport 
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>
      <div v-if="reportType == 'pharmacy-report'">
        <PharmacyReport
        :dataModal="dataModal"
        :excel="excel"
        />
      </div>

    </b-modal>

  </div>
</template>

<script>

import PaymentReport from '@/component/report-modal-tables/PaymentReport.vue'
import ExpenseReport from '@/component/report-modal-tables/ExpenseReport.vue'
import PaymentPharmacyReport from '@/component/report-modal-tables/PaymentPharmacyReport.vue'
import PharmacyExpenseReport from '@/component/report-modal-tables/PharmacyExpenseReport.vue'
import EcommerceRecordReport from '@/component/report-modal-tables/EcommerceRecordReport.vue'
import DoctorCommisionReportTable from '@/component/report-modal-tables/DoctorCommisionReportTable.vue'
import EmployeeCommisionReportTable from '@/component/report-modal-tables/EmployeeCommisionReportTable.vue'
import PayrollReport from '@/component/report-modal-tables/PayrollReport.vue'
import PharmacyReport from '@/component/report-modal-tables/PharmacyReport.vue'


export default {

  props: {
    reportType:String,
    reportName:String,
    dataModal:Array,
    excel:Array,

  },

  components: {
    PaymentReport,
    ExpenseReport,
    PaymentPharmacyReport,
    PharmacyExpenseReport,
    EcommerceRecordReport,
    DoctorCommisionReportTable,
    EmployeeCommisionReportTable,
    PayrollReport,
    PharmacyReport,
  },

  methods: {

  //   chosenMedicine(value) {
  //     this.$emit('chosenMedicine', value)
  //   },
    
  //  packageMedicine(datas) {
  //     this.$emit("packageMedicine", datas);      
  //   },


  },
  mounted(){
  }

}
</script>

<style>
</style>