<template>
  <div>
    <div class="d-flex justify-content-start align-items-center mt-5">
      <!-- <button
                  class="btn btn-success mr-1"
                  v-b-tooltip.hover
                  title="Grafik"
                  @click="$router.push('/report/pharmacy-chart')"
                ><i class="fas fa-chart-bar px-0"></i></button> -->
      <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      <button
        class="btn btn-info mr-1"
        v-b-tooltip.hover
        title="Download Pdf"
        @click="btnDownloadOnClick"
      >
        <i class="fas fa-file-pdf px-0"></i>
      </button>
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      >
        <i class="fas fa-file-excel px-0"></i>
      </button>
    </div>

    <b-table
      hover
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive
    >
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(employee_name)="data">
        {{
          data.item.employee_name + " (" + data.item.employee_number_id + ")"
        }}
      </template>

      <template #cell(remuneration_name)="data">
        <div
          v-for="(name, index) in data.item.remuneration_type_name"
          :key="index"
        >
          <li>
            {{
              data.item.remuneration_type_name[index] +
              " (" +
              data.item.quantity[index] +
              " X " +
              parseInt(data.item.remuneration_ammount[index]).toLocaleString(
                "id-ID"
              ) +
              ")"
            }}
          </li>
        </div>
      </template>

      <template #cell(ammount)="data">
        {{ "Rp " + parseInt(data.item.ammount).toLocaleString("id-ID") }}
      </template>

      <template #cell(payment_method)="data">
        {{ data.item.payment_name }}
      </template>

      <template #cell(paid_date)="data">
        {{ data.item.display_paid_date }}
      </template>

      <template #cell(display_paid_month)="data">
        {{ data.item.display_paid_month }}
      </template>
    </b-table>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Penggajian'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="780px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <template>
          <div id="print">
            <div class="d-flex justify-content-center align-items-center mt-3">
              <h5>Laporan Penggajian</h5>
            </div>
            <div class="row">
              <div class="col-12">
                <b-table
                  hover
                  :items="items"
                  :fields="fieldsReport"
                  :style="'width: 595pt;'"
                  responsive
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(employee_name)="data">
                    {{
                      data.item.employee_name +
                      " (" +
                      data.item.employee_number_id +
                      ")"
                    }}
                  </template>

                  <template #cell(remuneration_name)="data">
                    <div
                      v-for="(name, index) in data.item.remuneration_type_name"
                      :key="index"
                    >
                      <li>
                        {{
                          data.item.remuneration_type_name[index] +
                          " (" +
                          data.item.quantity[index] +
                          " X " +
                          parseInt(
                            data.item.remuneration_ammount[index]
                          ).toLocaleString("id-ID") +
                          ")"
                        }}
                      </li>
                    </div>
                  </template>

                  <template #cell(ammount)="data">
                    {{
                      "Rp " +
                      parseInt(data.item.ammount).toLocaleString("id-ID")
                    }}
                  </template>

                  <template #cell(payment_method)="data">
                    {{ data.item.payment_name }}
                  </template>

                  <template #cell(paid_date)="data">
                    {{ data.item.display_paid_date }}
                  </template>

                  <template #cell(display_paid_month)="data">
                    {{ data.item.display_paid_month }}
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </template>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import XLSX from "xlsx";
import VueHtml2pdf from "vue-html2pdf";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  props: {
    // hasPatient: Boolean,
    // items: Array,
    // summary: Array,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number,
    dataModal: Array,
    excel: Array,
  },

  components: {
    Card,
    VueHtml2pdf,
    XLSX,
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "no",
          label: "No",
          sortable: false,
        },
        {
          key: "employee_name",
          label: "Nama Karyawan",
          sortable: true,
        },
        {
          key: "remuneration_name",
          label: "Remunerasi (qty X Jml)",
          sortable: false,
        },
        {
          key: "ammount",
          label: "Jumlah(Rp)",
          sortable: false,
        },
        {
          key: "payment_method",
          label: "Metode Pembayaran",
          sortable: false,
        },
        {
          key: "paid_date",
          label: "Tanggal Dibayar",
          sortable: true,
        },
        {
          key: "display_paid_month",
          label: "Periode",
          sortable: true,
        },
      ],
      fieldsReport: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "employee_name",
          label: "Nama Karyawan",
        },
        {
          key: "remuneration_name",
          label: "Remunerasi (qty X Jml)",
        },
        {
          key: "ammount",
          label: "Jumlah(Rp)",
        },
        {
          key: "payment_method",
          label: "Metode Pembayaran",
        },
        {
          key: "paid_date",
          label: "Tanggal Dibayar",
        },
        {
          key: "display_paid_month",
          label: "Periode",
        },
      ],
      // Table Items
      items: [],
      // Other
      dataLoaded: false,
      manipulateBtn: false,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,

      filter: {
        employee_name: "",
        start_date: "",
        end_date: "",
      },
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.list();
    },

    perPageNumber() {
      this.list();
    },

    async list() {
      this.items = this.dataModal;
      // this.items = await module.list('payrolls')
      this.dataLoaded = true;
    },

    resetFilter() {
      this.filter.employee_name = "";
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.list();
    },

    generalFilter() {
      this.list();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("payrolls/" + id);
      // If Deleted
      if (result) {
        this.list();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "7004") {
          this.manipulateBtn = true;
        }
      }
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print");
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },

    btnExcelOnClick() {
      let remuneration_type_name = "";

      let excel = [];
      this.excel.forEach((element) => {
        let remun_text = "";
        element.quantity.forEach((elem, i) => {
          remun_text += `${element.remuneration_type_name[i]} (${element.quantity[i]} X ${element.remuneration_ammount[i]}), `;
        });

        excel.push({
          "Nama karyawan": element.employee_name,
          "Remunerasi (qty X Jml)": remun_text.replace(/,\s*$/, ''),
          "Jumlah": element.ammount,
          "Metode Pembayaran": element.payment_name,
          "Tanggal dibayar": element.paid_date.split(" ")[0],
          "Periode": element.display_paid_month,
        });
      });

      // const excel = this.excel.map(obj => ({
      //   "Nama karyawan": obj.employee_name,
      //   "Remunerasi (qty X Jml)": obj.remuneration_type_name,
      //   "Tanggal": obj.display_paid_date,
      // }))
      const data = XLSX.utils.json_to_sheet(excel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Laporan Penggajian.xlsx");
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Laporan Penggajian" }]);
    // Get Data
    this.list();
    this.setActiveMenu();
  },
};
</script>
